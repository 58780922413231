import React, { useState } from 'react';
import { Box, Button, Flex, Text, IconButton } from '@chakra-ui/react';
import Typewriter from 'typewriter-effect';
import { FiVolume2 } from 'react-icons/fi';  // Icon for sound
import * as dictionary from '../dictionary.json';

const ReadingComponent = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayContent, setDisplayContent] = useState(''); // Accumulate text for display
  const [showChoices, setShowChoices] = useState(false);
  const [feedback, setFeedback] = useState('');

  // Move to the next content section
  const nextContent = () => {
    if (currentIndex < data.content.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setShowChoices(false);
      setFeedback('');
    } else {
      // Handle completion of all sections here
      console.log('Completed all sections');
    }
  };

  // Shuffle array function
  const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Handle user selection
  const handleSelection = (choice) => {
    const correctAnswer = data.choices[currentIndex].correct;
    if (choice === correctAnswer) {
      setFeedback('Correct!');
    } else {
      setFeedback('Wrong!');
    }

    // Proceed after 2 seconds
    setTimeout(() => {
      nextContent()
      // Append correct word to the display content
      setDisplayContent(prev => `${prev} ${data.content[currentIndex]} ${correctAnswer}`);
    }, 2000);
  };

  // Initialize typewriter effect and manage transitions
  const handleTypingDone = () => {
    if (data.choices[currentIndex]) {
      setShowChoices(true);
    } else {
      setDisplayContent(prev => `${prev} ${data.content[currentIndex]}`); // Append current content
      nextContent();
    }
  };
  
  // Play sound associated with the word
  const playSound = (url) => {
    new Audio(url).play();
  };

  const currentChoices = data.choices[currentIndex];

  const getChoiceHTML = (char, idx) => {
    const charData = dictionary[char];
    return (
      <Flex key={'choice-option-' + idx} align="center" my={1}>
        <Text fontSize="md" mr={2}>{char} - {charData.pinyin}</Text>
        <IconButton
          aria-label="Play sound"
          icon={<FiVolume2 />}
          size="sm"
          onClick={(e) => {
            e.stopPropagation(); // Prevent the selection action
            playSound(charData.sound);
          }}
          variant="ghost"
        />
        <Text fontSize="sm">{charData.description}</Text>
      </Flex>
    )
  }

  return (
    <Flex direction="column" height="100vh" align="center" justify="center" p={4} bg="gray.100">
      <Box width="100%" maxW="960px" bg="white" p={5} shadow="md" borderRadius="lg">
        <Text mt={2} whiteSpace="pre-wrap">{displayContent}</Text>
        <Typewriter
          key={currentIndex}  // Ensuring Typewriter restarts on currentIndex change
          options={{ delay: 20 }}
          onInit={(typewriter) => {
            typewriter.typeString(data.content[currentIndex])
              .callFunction(() => {
                handleTypingDone();
              })
              .start();
          }}
        />
        
        {showChoices && currentChoices && (
          <Flex direction="column" align="center" justify="center">
          {shuffleArray(Object.keys(currentChoices)).map((choiceKey, index) => {
            const currentOption = currentChoices[choiceKey];
            const characters = currentOption.split('');
            return (
              <Box key={'choice-' + index} p={4} m={2} w="90%" bg="gray.50" borderRadius="md" boxShadow="md"
                   onClick={() => handleSelection(currentOption)}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>{currentOption}</Text>
                {
                  dictionary[currentOption] && getChoiceHTML(currentOption, index)
                }
                {
                  characters.map((char, idx) => {
                    if (!dictionary[char]) return null;
                    return getChoiceHTML(char, idx);
                  })
                }
              </Box>
            );
          })}
        </Flex>
        )}
        <Text fontSize="xl" mt={2}>{feedback}</Text>
      </Box>
    </Flex>
  );
};

export default ReadingComponent;
