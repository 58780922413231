import React from 'react';
import { Box, Button, Heading, Text, VStack, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <VStack spacing={8} align="center" justify="center" height="100vh" padding={4}>
      <Box textAlign="center">
        <Heading as="h1" size="2xl" mb={2}>Welcome to the Chinese Learning App</Heading>
        <Text fontSize="xl">Start your journey to mastering Chinese today!</Text>
      </Box>

      <Box>
        <Image borderRadius="full" boxSize="150px" src="https://source.unsplash.com/random/150x150?chinese,culture" alt="Chinese Culture" />
      </Box>

      <VStack spacing={4}>
        <Link to="/">
          <Button colorScheme="teal" size="lg">Story Mode</Button>
        </Link>
        <Link to="/quiz">
          <Button colorScheme="teal" size="lg">Quizzes</Button>
        </Link>
      </VStack>
    </VStack>
  );
};

export default Home;
