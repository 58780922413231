import React, { useState } from 'react';
import { Box, Button, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';

const quizData = [
  {
    question: "What does '你好' mean in English?",
    options: ['Hello', 'Goodbye', 'Thank you', 'Please'],
    correctAnswer: 'Hello'
  },
  {
    question: "How do you say 'Thank you' in Chinese?",
    options: ['谢谢', '你好', '再见', '是'],
    correctAnswer: '谢谢'
  }
];

const Quiz = () => {
  const [answers, setAnswers] = useState({});
  const [results, setResults] = useState(null);

  const handleOptionChange = (question, option) => {
    setAnswers(prev => ({ ...prev, [question]: option }));
  };

  const handleSubmit = () => {
    const evaluation = quizData.map(q => ({
      question: q.question,
      correct: answers[q.question] === q.correctAnswer
    }));
    setResults(evaluation);
  };

  return (
    <Box p={5}>
      {quizData.map((q, index) => (
        <Box key={index} p={5} shadow="md" borderWidth="1px" borderRadius="lg" overflow="hidden">
          <Text p={2} fontSize="lg">{q.question}</Text>
          <RadioGroup onChange={(val) => handleOptionChange(q.question, val)}>
            <Stack direction="column">
              {q.options.map((option, idx) => (
                <Radio key={idx} value={option}>
                  {option}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Box>
      ))}
      <Button mt={4} colorScheme="teal" onClick={handleSubmit}>Submit</Button>
      {results && (
        <Box mt={4}>
          {results.map((result, idx) => (
            <Text key={idx} fontSize="md">
              {result.question} - {result.correct ? "Correct" : "Incorrect"}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Quiz;
