import React, { useState, useEffect } from 'react';
import { Box, Button, Text, VStack, Stack, Badge, Flex } from '@chakra-ui/react';
import { firestore } from '../firebase-config';
import { useAuth } from '../AuthContext';  // Ensure AuthContext is correctly set up
import Reading from '../components/Reading';

const StoryOutline = () => {
  const { currentUser } = useAuth();
  const [mode, setMode] = useState('selecting');  // ['selecting', 'reading']
  const [levels, setLevels] = useState([]);
  const [currency, setCurrency] = useState(50); // Adjust initial eRMB as needed

  useEffect(() => {
    if (!currentUser) return; // Ensure currentUser is available before proceeding (e.g., after login

    // Local Util: Uncomment to copy subsections from one level to all levels
    // firestore.collection('levels').doc('1').collection('subsections').doc('2').get().then(doc => {
    //   firestore.collection('levels').doc('1').collection('subsections').doc('1').set({...doc.data(), id: 1});
    //   firestore.collection('levels').doc('2').collection('subsections').doc('1').set({...doc.data(), id: 1});
    //   firestore.collection('levels').doc('3').collection('subsections').doc('1').set({...doc.data(), id: 1});
    //   firestore.collection('levels').doc('4').collection('subsections').doc('1').set({...doc.data(), id: 1});
    // });
    // Fetch levels from Firestore
    const levelsRef = firestore.collection('levels');
    levelsRef.get().then(snapshot => {
      const loadedLevels = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id, // Ensure the 'id' is captured if not already included in doc.data()
        subsections: [], // Initialize subsections if needed
        currentSubsectionIndex: 0,
        isLocked: true, // Default to locked until user data is checked
        completed: false
      }));

      // Fetch subsections subcollection for each level
      const subsectionsPromises = Promise.all(loadedLevels.map(level => {
        return levelsRef.doc(level.id).collection('subsections').get().then(subsectionSnapshot => {
          const subsections = subsectionSnapshot.docs.map(subDoc => subDoc.data());
          return { levelId: level.id, subsections: subsections || [] };
        });
      }));

      // Fetch user profile and progress, then update state
      const userProfileRef = firestore.collection('profile').doc(currentUser.uid);
      const progressRef = firestore.collection('progress').doc(currentUser.uid);
      Promise.all([subsectionsPromises, userProfileRef.get(), progressRef.get()]).then(([subsectionsForLevels, userProfileDoc, progressDoc]) => {
        let newCurrency = 50; // Default value
        if (userProfileDoc.exists) {
          newCurrency = userProfileDoc.data().currency;
        } else {
          // Consider setting default profile if not exists
          userProfileRef.set({ currency: 50, name: '' });
        }

        if (progressDoc.exists) {
          const progressData = progressDoc.data();
          const updatedLevels = loadedLevels.map(level => ({
            ...level,
            isLocked: !progressData.unlockedLevels.includes(Number(level.id)),
            currentSubsectionIndex: progressData.currentSubsection ? progressData.currentSubsection[level.id] : 0,
            subsections: (subsectionsForLevels.find(data => data.levelId == level.id).subsections || []).map(sub => ({
              ...sub,
              completed: progressData.completedSubsections ? progressData.completedSubsections[level.id].includes(sub.id) : false
            }))
          }));
          setLevels(updatedLevels); // Set levels with all data consolidated
          setCurrency(newCurrency);
        } else {
          // Setup default progress if not exists
          progressRef.set({
            unlockedLevels: [loadedLevels[0].id], // Unlock the first level by default
            completedLevels: [],
            currentSubsection: {}
          });
          setLevels(loadedLevels.map((level, index) => ({...level, isLocked: index !== 0}))); // Unlock the first level
          setCurrency(newCurrency);
        }
      });
    });
  }, [currentUser.uid]);

  const [viewingLevelDetails, setViewingLevelDetails] = useState(false);
  const [activeLevelId, setActiveLevelId] = useState(null);
  const [activeSubsection, setActiveSubsection] = useState(null);


  const unlockLevel = (levelCost, levelId) => {
    if (currency >= levelCost) {
      setCurrency(currency - levelCost);
      setLevels(levels.map(level =>
        level.id === levelId ? { ...level, isLocked: false, unlocked: true } : level
      ));

      // Save progress and currency in Firestore
      firestore.collection('progress').doc(currentUser.uid).set({
        currency: currency - levelCost,
        unlockedLevels: levels.filter(level => level.id === levelId || level.unlocked).map(level => level.id),
        completedLevels: levels.filter(level => level.completed).map(level => level.id)
      }, { merge: true });
    } else {
      alert("Not enough eRMB to unlock this level!");
    }
  };

  const enterLevel = (levelId) => {
    const level = levels.find(l => l.id === levelId);
    if (level && !level.isLocked) {
      const currentSubsection = level.subsections[level.currentSubsectionIndex];
      // Navigate to the active subsection's view, handling the UI presentation as needed
      console.log('Entering:', currentSubsection.name);
      setActiveLevelId(levelId);
      setViewingLevelDetails(true);  // Switch view to show subsections of the level
    }
  };

  const goToNextSubsection = (levelId) => {
    setLevels(levels.map(level => {
      if (level.id === levelId) {
        const nextIndex = level.currentSubsectionIndex + 1 < level.subsections.length ? level.currentSubsectionIndex + 1 : level.currentSubsectionIndex;
        return { ...level, currentSubsectionIndex: nextIndex };
      }
      return level;
    }));
  };
  
  const exitLevelDetails = () => {
    setViewingLevelDetails(false);
    setActiveLevelId(null);
  };

  const completeLevel = (levelId) => {
    setLevels(levels.map(level =>
      level.id === levelId ? { ...level, completed: true } : level
    ));

    // Optionally update Firestore here too
    firestore.collection('progress').doc(currentUser.uid).set({
      completedLevels: levels.filter(level => level.id === levelId || level.completed).map(level => level.id)
    }, { merge: true });
  };

  const currentLevel = levels.find(level => level.id === activeLevelId)

  return mode === 'selecting' ? (
    <VStack spacing={4} p={5}>
      <Text fontSize="2xl">Your eRMB: {currency}</Text>
      {!viewingLevelDetails ? (
        <Stack direction={{ base: "column" }} spacing={4}>
          {levels.map((level, levelIndex) => (
            <Box key={levelIndex + 'level-' + level.id} p={5} borderWidth="1px" borderRadius="lg">
              <Text fontSize="xl">{level.name} {level.isLocked && <Badge colorScheme="red">Locked</Badge>}</Text>
              <Text>{level.description}</Text>
              {level.isLocked ? (
                <Button colorScheme="teal" onClick={() => unlockLevel(level.cost, level.id)}>
                  Unlock for {level.cost} RMB
                </Button>
              ) : (
                <Button colorScheme="green" onClick={() => enterLevel(level.id)}>Enter Level</Button>
              )}
            </Box>
          ))}
        </Stack>
      ) : (
        <Stack direction={{ base: "column" }} spacing={4}>
          <Button colorScheme="teal" onClick={exitLevelDetails} mb={4}>Back to all levels</Button>
          {levels.find(level => level.id === activeLevelId)?.subsections.map((subsection, index) => (
            <Box key={index + 'subsection-' + subsection.id} p={5} borderWidth="1px" borderRadius="lg">
              <Text fontSize="xl">{subsection.name}</Text>
              {/* If we are on the active subsection, show a 'continue' button */}
              { levels.find(level => level.id === activeLevelId).currentSubsectionIndex === levels.find(level => level.id === activeLevelId).subsections.indexOf(subsection) && (
                <Button colorScheme="green" onClick={() => setMode('reading')}>Continue story</Button>
              )}
            </Box>
          ))}
        </Stack>
      )}
    </VStack>
  ) : (
    <VStack spacing={4} p={5}>
      <Text fontSize="2xl">Your eRMB: {currency}</Text>
      <Stack direction={{ base: "column" }} spacing={4}>
        <Reading data={currentLevel.subsections[currentLevel.currentSubsectionIndex]} />
      </Stack>
    </VStack>
  );
};

export default StoryOutline;
