import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea, useToast } from '@chakra-ui/react';
import { firestore } from '../firebase-config'; // Ensure your Firestore config is properly set up

const DataEntry = () => {
  const [levelId, setLevelId] = useState('');
  const [subsectionId, setSubsectionId] = useState('');
  const [jsonData, setJsonData] = useState('');
  const toast = useToast();

  const handleSaveData = async () => {
    if (!levelId || !subsectionId || !jsonData) {
      toast({
        title: "Error",
        description: "All fields must be filled out correctly!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const parsedData = JSON.parse(jsonData);
      await firestore.collection('levels').doc(levelId)
        .collection('subsections').doc(subsectionId)
        .set(parsedData);

      toast({
        title: "Success",
        description: "Data has been saved successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Optionally clear fields after successful submission
      setLevelId('');
      setSubsectionId('');
      setJsonData('');
    } catch (error) {
      toast({
        title: "Error",
        description: "Invalid JSON data provided.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <FormControl isRequired>
        <FormLabel>Level ID</FormLabel>
        <Input value={levelId} onChange={e => setLevelId(e.target.value)} placeholder="Enter Level ID" />
      </FormControl>
      <FormControl isRequired mt={4}>
        <FormLabel>Subsection ID</FormLabel>
        <Input value={subsectionId} onChange={e => setSubsectionId(e.target.value)} placeholder="Enter Subsection ID" />
      </FormControl>
      <FormControl isRequired mt={4}>
        <FormLabel>JSON Data</FormLabel>
        <Textarea value={jsonData} onChange={e => setJsonData(e.target.value)} placeholder="Enter JSON Data" />
      </FormControl>
      <Button onClick={handleSaveData} colorScheme="blue" mt={4}>Save Data</Button>
    </Box>
  );
};

export default DataEntry;
