import React from 'react';
import { Box, Flex, Text, Button, useColorMode, IconButton } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaSun, FaMoon } from 'react-icons/fa';

const Navbar = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  
  return (
    <Flex as="nav" align="center" justify="space-between" wrap="wrap" padding="1.5rem" bg="teal.500" color="white">
      <Flex align="center" mr={5}>
        <Text fontSize="lg" fontWeight="bold">
          Chinese Learning App
        </Text>
      </Flex>

      <Box display={{ sm: 'block', md: 'none' }}>
        <IconButton
          icon={colorMode === 'light' ? <FaSun /> : <FaMoon />}
          onClick={toggleColorMode}
          variant="outline"
          aria-label="Toggle Color Mode"
        />
      </Box>

      <Box display={{ sm: 'block', md: 'flex' }} width={{ sm: 'full', md: 'auto' }} alignItems="center" flexGrow={1}>
      <Text margin="0 10px">
          <Link to="/">Story</Link>
        </Text>
        <Text margin="0 10px">
          <Link to="/home">Home</Link>
        </Text>
        <Text margin="0 10px">
          <Link to="/quiz">Quiz</Link>
        </Text>
      </Box>

      <Box>
        <Button bg="transparent" border="1px" onClick={toggleColorMode}>
          {colorMode === 'light' ? 'Dark' : 'Light'} Mode
        </Button>
      </Box>
    </Flex>
  );
};

export default Navbar;
