import React, { useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const { signInWithGoogle, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/'); // Redirects to Home page
    }
  }, [currentUser, navigate]);

  const handleSignIn = async () => {
    try {
      await signInWithGoogle();
      // Navigation after sign-in is handled by the useEffect
    } catch (error) {
      console.error('Failed to sign in:', error);
    }
  };

  return (
    <div>
      <Button onClick={handleSignIn}>Sign in with Google</Button>
    </div>
  );
};

export default SignIn;
