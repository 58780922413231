import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import StoryOutline from './pages/StoryOutline';
import Quiz from './pages/Quiz';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import SignIn from './pages/SignIn';
import DataEntry from './pages/DataEntry';

const App = () => {
  return (
    <ChakraProvider>
      <Router>
        <AuthProvider>
          <Navbar />
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/" element={<ProtectedRoute><StoryOutline /></ProtectedRoute>} />
            <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="/dataentry" element={<ProtectedRoute><DataEntry /></ProtectedRoute>} />
            <Route path="/quiz" element={<ProtectedRoute><Quiz /></ProtectedRoute>} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
};

export default App;