import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDxvGJiBWcRWvAW7KXoZk8FTAThMgZtT_0",
    authDomain: "chinese-school-f8a4a.firebaseapp.com",
    projectId: "chinese-school-f8a4a",
    storageBucket: "chinese-school-f8a4a.appspot.com",
    messagingSenderId: "337470604477",
    appId: "1:337470604477:web:452cf9ae3eca24676b63d4",
    measurementId: "G-0SM3MSMLBS"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = getAnalytics();
export const googleProvider = new firebase.auth.GoogleAuthProvider();